import {useQuery} from 'react-query';
import client from '../services/client';

export const getCustomers = (enabled: boolean = true) => {
  return useQuery({
    queryKey: ['customers'],
    queryFn: () => client.get('customer').then(response => response.data),
    initialData: [],
    enabled: enabled
  });
};

export const getCustomerData = () => {
  return useQuery({
    queryKey: ['customerData'],
    queryFn: () => client.get('customer/data').then(response => response.data),
    initialData: {
      name: '',
      email: '',
      phone: '',
      address: '',
      postalCode: '',
      city: '',
      country: '',
      employees: [],
      contacts: [],
    },
    cacheTime: 0,
  });
};

export const getCustomerSubscriptions = (id: number) => {
  return useQuery({
    queryKey: ['customer', id],
    queryFn: () => client.get(`customer/${id}/project`).then(response => response.data),
    initialData: [],
    enabled: id !== 0,
  });
};
