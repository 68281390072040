import React, {useContext} from 'react';
import {ColumnDefinitionType, ProjectLink, TableAction} from '../../types';
import DataTable from '../../elements/DataTable/DataTable';
import {AddBox, DeleteOutlined, EditOutlined, Search} from "@mui/icons-material";
import {UserContext} from "../../provider/UserProvider";
import {hasRole} from "../../services/role";
import {useNavigate} from "react-router-dom";
import {getProjectLinks} from '../../api/project';
import {deleteLink} from '../../api/link';
import {Paper} from '@mui/material';

const Overview = () => {
  const navigate = useNavigate();
  const {user} = useContext(UserContext);
  const {data: links, isLoading, isFetching} = getProjectLinks();
  const mutation = deleteLink();

  const columns: Array<ColumnDefinitionType<ProjectLink>> = [
    { key: 'description', title: 'Beschrijving', type: 'string', sortable: true },
    { key: 'project.domain', title: 'Project', type: 'string', sortable: true },
    { key: 'customer.name', title: 'Klant', type: 'string', sortable: true, condition: () => user.internal },
  ];

  const toolbarActions: Array<TableAction<undefined>> = [
    {
      title: 'Link aanmaken',
      icon: <AddBox />,
      variant: 'primary',
      onClick: () => navigate('/link/new'),
      type: 'button' ,
      condition: () => hasRole(user, ['ROLE_PROJECT_LINK_CREATE']),
    },
  ];

  const actions: Array<TableAction<ProjectLink>> = [
    { title: 'Bekijken', icon: <Search />, variant: 'primary', onClick: (link) => window.open(link.url, '_blank'), type: 'button', condition: () => hasRole(user, ['ROLE_PROJECT_LINK_GET']) },
    { title: 'Bewerken', icon: <EditOutlined />, variant: 'primary', onClick: (link) => navigate(`/link/${link.id}`), type: 'button', condition: () => hasRole(user, ['ROLE_PROJECT_LINK_UPDATE']) },
    { title: 'Verwijderen', icon: <DeleteOutlined />, variant: 'danger', onClick: mutation.mutate, type: 'button', condition: () => hasRole(user, ['ROLE_PROJECT_LINK_DELETE']) },
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={links}
        loading={isLoading || isFetching}
        actions={actions}
        onRowClick={(link) => window.open(link.url, '_blank')}
        toolbar={{
          title: 'Links',
          actions: toolbarActions,
        }}
      />
    </Paper>
  );
};

export default Overview;
